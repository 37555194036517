import React, { useState, useEffect } from 'react';

export default function CriteriaFilter({options, defaultOption, onSelectedChange }) {
    const [selected, setSelected] = useState([defaultOption]);

    const handleCheckboxChange = (option) => {
        let newSelected;
        // console.log(option);
        if (selected.includes(option)) {
          newSelected = selected.filter((item) => item !== option);
        } else {
          newSelected = [...selected, option];
        }
        setSelected(newSelected);
        onSelectedChange(newSelected);
      };

      useEffect(() => {
        function handleReceiveMessage(event) {
            // Stellen Sie sicher, dass die Nachricht von der erwarteten Quelle kommt
            // if (event.origin !== 'https://the-expected-origin.com') return;

            // Füge die empfangene Option zu den ausgewählten Optionen hinzu
            if(event.data !== 'back' && event.data !== 'forward') { 
                handleCheckboxChange(event.data);
            }
           
        }

        window.addEventListener('message', handleReceiveMessage);

        return () => {
            window.removeEventListener('message', handleReceiveMessage);
        };
    }, [selected]);

    return (
        <div className='filter-container'>
            <h3>Was ist Ihnen wichtig? Wo liegt Ihr Fokus?</h3>
            <div className="filter">
                {options.map((option, index) => (
                    <div className="filter-item" key={index}>
                        <input
                            type="checkbox"
                            id={`filter-${index}`}
                            checked={selected.includes(option)}
                            onChange={() => handleCheckboxChange(option)}
                        />
                        <label htmlFor={`filter-${index}`}>{option}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}


