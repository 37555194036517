import React from 'react'
import software from '../../software.json';

export default function Server(props) {

    const { name, height, subheading } = props;
  const mainDivClass = subheading
  ? 'server basics-criteria values mobil-heading'
  : 'server basics-criteria values';

  return (
    <div className={mainDivClass}>
    <div style={{ height: `${height[7] - 31.1}px` }} className="criteria-one criteria-design">
        {software[name].serverAccess.onPremiseServer}
    </div>
    <div style={{ height: `${height[8] - 31.1}px` }} className="criteria-two criteria-design">
        {software[name].serverAccess.ownCloudServer}
    </div>
    <div style={{ height: `${height[9] - 31.1}px` }} className="criteria-three criteria-design">
        {software[name].serverAccess.cloudAsSaaS}
    </div>
    <div style={{ height: `${height[10] - 31.1}px` }} className="criteria-four criteria-design">
        {software[name].serverAccess.browserAccessToSaaS}
    </div>
    <div style={{ height: `${height[11] - 31.1}px` }} className="criteria-five criteria-design">
        {software[name].serverAccess.appAccessToSaaS}
    </div>
    <div style={{ height: `${height[12] - 31.1}px` }} className="criteria-six criteria-design">
        {software[name].serverAccess.saasInclSoftwareEmailOfficeVideoChat}
    </div>
</div>
  )
}
