import React from 'react'
import software from '../../software.json';

export default function Communication( props ) {

    const { name, height, subheading } = props;

    const mainDivClass = subheading
      ? 'communication basics-criteria values mobil-heading'
      : 'communication basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[13] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].communication.beAIntegration}
            </div>
            <div style={{ height: `${height[14] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].communication.emailIntegration}
            </div>
            <div style={{ height: `${height[15] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].communication.webFile}
            </div>
        </div>
    )
}
