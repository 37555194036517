import React from 'react';
import software from '../../software.json';

export default function MobileWorking(props) {
  
  
  const { name, height, subheading } = props;
  const mainDivClass = subheading
  ? 'mobile-working basics-criteria values mobil-heading'
  : 'mobile-working basics-criteria values';

  return (
    <div className={mainDivClass}>
      <div style={{ height: `${height[18] - 31.1}px` }} className="criteria-one criteria-design">
        {software[name].mobileWorking.offlineAccess}
      </div>
      <div style={{ height: `${height[19] - 31.1}px` }} className="criteria-two criteria-design">
        {software[name].mobileWorking.syncOfflineChanges}
      </div>
      <div style={{ height: `${height[20] - 31.1}px` }} className="criteria-three criteria-design">
        {software[name].mobileWorking.cloudAccessViaBrowserApp}
      </div>
      <div style={{ height: `${height[21] - 31.1}px` }} className="criteria-four criteria-design">
        {software[name].mobileWorking.appointmentTaskManagementOnPhone}
      </div>
    </div>
  );
}
