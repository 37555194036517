import React from 'react';
import software from '../../software.json';

export default function Support(props) {
    const { name } = props;

    return (
      <div className="support basics-criteria">
        <div className="criteria-one criteria-31 criteria-design origin">
          {software[name].support.phoneSupport}
        </div>
        <div className="criteria-two criteria-32 criteria-design origin">
          {software[name].support.avgWaitTimeHotline}
        </div>
      </div>
    );
}
