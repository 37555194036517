import React from 'react';
import software from '../../software.json';

export default function Focus(props) {
    const { name } = props;

    return (
      <div className="focus basics-criteria">
        <div className="criteria-one criteria-33 criteria-design origin">
          {software[name].focus.suitableForFirmSizes}
        </div>
        <div className="criteria-two criteria-34 criteria-design origin">
          {software[name].focus.limitedToSpecificAreas}
        </div>
        <div className="criteria-three criteria-35 criteria-design origin">
          {software[name].focus.notaryFeatures}
        </div>
      </div>
    );
}
