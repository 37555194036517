import React from 'react'
import software from '../../software.json'

export default function Server(props) {

    const { name } = props

    return (
        <div className="server basics-criteria">
            <div className="criteria-one criteria-8 criteria-design origin">
                {software[name].serverAccess.ownPremiseServer}
            </div>
            <div className="criteria-two criteria-9 criteria-design origin">
                {software[name].serverAccess.ownCloudServer}
            </div>
            <div className="criteria-three criteria-10 criteria-design origin">
                {software[name].serverAccess.cloudAsSaaS}
            </div>
            <div className="criteria-four criteria-11 criteria-design origin">
                {software[name].serverAccess.browserAccessToSaaS}
            </div>
            <div className="criteria-five criteria-12 criteria-design origin">
                {software[name].serverAccess.appAccessToSaaS}
            </div>
            <div className="criteria-six criteria-13 criteria-design origin">
                {software[name].serverAccess.saasInclSoftwareEmailOfficeVideoChat}
            </div>
        </div>
    )
}
