import React from 'react'
import software from '../../software.json'

export default function Communication(props) {

    const { name } = props


    return (
        <div className="communication basics-criteria">
            <div className="criteria-one criteria-14 criteria-design origin">
                {software[name].communication.beAIntegration}
            </div>
            <div className="criteria-two criteria-15 criteria-design origin">
                {software[name].communication.emailIntegration}
            </div>
            <div className="criteria-three criteria-16 criteria-design origin">
                {software[name].communication.webFile}
            </div>
        </div>
    )
}
