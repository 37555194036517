import React from 'react'
import software from '../software.json'

export default function CriteriaGrade(props) {
    const { name } = props

    return (
      <div className="product-grade-container criteria">
          <div className="product-grade">
              <span className="product-grade-heading">
                  <b>{software[name].grade}</b>
                  <b>{software[name].gradeTwo}</b>
              </span>
          </div>
      </div>
    )
}
