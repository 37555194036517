import React, { useEffect, useState } from 'react';
import CriteriaHeader from './swiper_components/CriteriaHeader';
import CriteriaGrade from './swiper_components/CriteriaGrade';
import Basics from './swiper_components/criteria_container_components/Basics';
import Server from './swiper_components/criteria_container_components/Server';
import Communication from './swiper_components/criteria_container_components/Communication';
import Automation from './swiper_components/criteria_container_components/Automation';
import MobileWorking from './swiper_components/criteria_container_components/MobilWorking';
import UserInterface from './swiper_components/criteria_container_components/UserInterface';
import SpecializedDMS from './swiper_components/criteria_container_components/SpecializedDMS';
import Support from './swiper_components/criteria_container_components/Support';
import Focus from './swiper_components/criteria_container_components/Focus';
import Updates from './swiper_components/criteria_container_components/Updates';
import Costs from './swiper_components/criteria_container_components/Costs';
import Rating from './swiper_components/criteria_container_components/Rating';

export default function SwiperSlideCriteria(props) {

    const { name, selected, subheading } = props;


    return (
        <div className="product-card">
            <CriteriaHeader name={name} />
            <CriteriaGrade name={name} />
            {selected.includes('Basics') && <Basics name={name} subheading={subheading}/>}
            {selected.includes('Server/Zugriff') && <Server name={name} subheading={subheading}/>}
            {selected.includes('Kommunikation') && <Communication name={name} subheading={subheading}/>}
            {selected.includes('Automation') && <Automation name={name} subheading={subheading}/>}
            {selected.includes('Mobiles Arbeiten') && <MobileWorking name={name} subheading={subheading}/>}
            {selected.includes('Benutzeroberfläche') && <UserInterface name={name} subheading={subheading}/>}
            {selected.includes('Spezialisiertes DMS') && <SpecializedDMS name={name} subheading={subheading}/>}
            {selected.includes('Support') && <Support name={name} subheading={subheading}/>}
            {selected.includes('Schwerpunkt') && <Focus name={name} subheading={subheading}/>}
            {selected.includes('Updates') && <Updates name={name} subheading={subheading}/>}
            {selected.includes('Kosten') && <Costs name={name} subheading={subheading}/>}
            {selected.includes('Bewertung') && <Rating name={name} subheading={subheading}/>}
        </div>
    )
}
