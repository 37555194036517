import React from 'react';
import software from '../../software.json';

export default function Updates(props) {
    
    const { name, height, subheading } = props;
    const mainDivClass = subheading
    ? 'updates basics-criteria values mobil-heading'
    : 'updates basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[35] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].updates.avgUpdateTime}
            </div>
            <div style={{ height: `${height[36] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].updates.avgUpdateInterval}
            </div>
            <div style={{ height: `${height[37] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].updates.dataImportFromExistingSoftware}
            </div>
            <div style={{ height: `${height[38] - 31.1}px` }} className="criteria-four criteria-design">
                {software[name].updates.autoUpdateInstallation}
            </div>
        </div>
    );
}
