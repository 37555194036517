import React from 'react';
import software from '../../software.json';

export default function UserInterface(props) {
    
    const { name, height, subheading } = props;
    const mainDivClass = subheading
    ? 'user-interface basics-criteria values mobil-heading'
    : 'user-interface basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[22] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].userInterface.usability}
            </div>
            <div style={{ height: `${height[23] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].userInterface.practicalStructure}
            </div>
            <div style={{ height: `${height[24] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].userInterface.multipleFilesOpenParallel}
            </div>
        </div>
    );
}
