import React from 'react'
import software from '../../software.json';

export default function UserInterface(props) {

    const { name } = props;

    return (
        <div className="user-interface basics-criteria">
            <div className="criteria-one criteria-23 criteria-design origin">
                {software[name].userInterface.usability}
            </div>
            <div className="criteria-two criteria-24 criteria-design origin">
                {software[name].userInterface.practicalStructure}
            </div>
            <div className="criteria-three criteria-25 criteria-design origin">
                {software[name].userInterface.multipleFilesOpenParallel}
            </div>
        </div>
    )
}
