import React from 'react';
import software from '../../software.json';

export default function SpecializedDMS(props) {
    
  const { name, height, subheading } = props;
  
  const mainDivClass = subheading
  ? 'specialized-dms basics-criteria values mobil-heading'
  : 'specialized-dms basics-criteria values';

    return (
      <div className={mainDivClass}>
        <div style={{ height: `${height[25] - 31.1}px` }} className="criteria-one criteria-design">
          {software[name].specializedDMS.directConnectionToFile}
        </div>
        <div style={{ height: `${height[26] - 31.1}px` }} className="criteria-two criteria-design">
          {software[name].specializedDMS.cloudAvailability}
        </div>
        <div style={{ height: `${height[27] - 31.1}px` }} className="criteria-three criteria-design">
          {software[name].specializedDMS.autoAssignmentBeaEmailAttachToFiles}
        </div>
        <div style={{ height: `${height[28] - 31.1}px` }} className="criteria-four criteria-design">
          {software[name].specializedDMS.taskSequencesWithDocReference}
        </div>
        <div style={{ height: `${height[29] - 31.1}px` }} className="criteria-five criteria-design">
          {software[name].specializedDMS.collaborativeEditing}
        </div>
      </div>
    );
}
