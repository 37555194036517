import React, { useState, useEffect } from 'react'; 
import './App.css';
import SwiperSlider from './components/SwiperSlider';
import CriteriaFilter from './components/swiper_container/swiper_components/CriteriaFilter';

function App() {

  const options = ["Basics", "Server/Zugriff", "Kommunikation", "Automation", "Mobiles Arbeiten", "Benutzeroberfläche", "Spezialisiertes DMS", "Support", "Schwerpunkt", "Updates", "Kosten", "Bewertung"];
  const defaultOption = "Basics";
  const [selected, setSelected] = useState([defaultOption]);

  const handleSelectedChange = (newSelected) => {
    setSelected(newSelected);
  };

  const sendHeight = () => {
    const productCardElement = document.getElementsByClassName('product-card')[0];

    // Die Höhe des Elements abrufen
    const productCardHeight = productCardElement.offsetHeight;

    // Die Höhe des Elements an das übergeordnete Fenster senden
    window.parent?.postMessage({height: productCardHeight}, 'https://legal-tech-check.de/');
  };

  useEffect(() => {
    // Höhe senden, wenn "selected" sich ändert
    sendHeight();

    // Event Listener hinzufügen, um auf Fenstergrößenänderungen zu reagieren
    window.addEventListener('resize', sendHeight);

    // Event Listener entfernen, wenn die Komponente unmountet wird
    return () => window.removeEventListener('resize', sendHeight);
  }, [selected]); // Wird ausgeführt, nachdem "selected" sich ändert

  return (
    <div className="App">
      <CriteriaFilter
        options={options}
        defaultOption={defaultOption}
        onSelectedChange={handleSelectedChange}
        selected={selected}
      />
      <SwiperSlider 
        selected={selected}
      />
    </div>
  );
}

export default App;
