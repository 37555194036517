import React from 'react';
import software from '../../software.json';

export default function SpecializedDMS(props) {
    const { name } = props;

    return (
      <div className="specialized-dms basics-criteria">
        <div className="criteria-one criteria-26 criteria-design origin">
          {software[name].specializedDMS.directConnectionToFile}
        </div>
        <div className="criteria-two criteria-27 criteria-design origin">
          {software[name].specializedDMS.cloudAvailability}
        </div>
        <div className="criteria-three criteria-28 criteria-design origin">
          {software[name].specializedDMS.autoAssignmentBeaEmailAttachToFiles}
        </div>
        <div className="criteria-four criteria-29 criteria-design origin">
          {software[name].specializedDMS.taskSequencesWithDocReference}
        </div>
        <div className="criteria-five criteria-30 criteria-design origin">
          {software[name].specializedDMS.collaborativeEditing}
        </div>
      </div>
    );
}
