import React from 'react';
import software from '../../software.json';

export default function Support(props) {
    
  const { name, height, subheading } = props;
  const mainDivClass = subheading
  ? 'support basics-criteria values mobil-heading'
  : 'support basics-criteria values';

    return (
      <div className={mainDivClass}>
        <div style={{ height: `${height[30] - 31.1}px` }} className="criteria-one criteria-design">
          {software[name].support.phoneSupport}
        </div>
        <div style={{ height: `${height[31] - 31.1}px` }} className="criteria-two criteria-design">
          {software[name].support.avgWaitTimeHotline}
        </div>
      </div>
    );
}
