import React from 'react';
import software from '../../software.json';

export default function Focus(props) {
    
    const { name, height, subheading } = props;
    const mainDivClass = subheading
    ? 'focus basics-criteria values mobil-heading'
    : 'focus basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[32] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].focus.suitableForFirmSizes}
            </div>
            <div style={{ height: `${height[33] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].focus.limitedToSpecificAreas}
            </div>
            <div style={{ height: `${height[34] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].focus.notaryFeatures}
            </div>
        </div>
    );
}
