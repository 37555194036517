import React from 'react';
import software from '../../software.json';

export default function Updates(props) {
    const { name } = props;

    return (
      <div className="updates basics-criteria">
        <div className="criteria-one criteria-36 criteria-design origin">
          {software[name].updates.avgUpdateTime}
        </div>
        <div className="criteria-two criteria-37 criteria-design origin">
          {software[name].updates.avgUpdateInterval}
        </div>
        <div className="criteria-three criteria-38 criteria-design origin">
          {software[name].updates.dataImportFromExistingSoftware}
        </div>
        <div className="criteria-four criteria-39 criteria-design origin">
          {software[name].updates.autoUpdateInstallation}
        </div>
      </div>
    );
}
