import React from 'react';
import software from '../../software.json';

export default function MobileWorking(props) {
  const { name } = props;

  return (
    <div className="mobile-working basics-criteria">
      <div className="criteria-one criteria-19 criteria-design origin">
        {software[name].mobileWorking.offlineAccess}
      </div>
      <div className="criteria-two criteria-20 criteria-design origin">
        {software[name].mobileWorking.syncOfflineChanges}
      </div>
      <div className="criteria-three criteria-21 criteria-design origin">
        {software[name].mobileWorking.cloudAccessViaBrowserApp}
      </div>
      <div className="criteria-four criteria-22 criteria-design origin">
        {software[name].mobileWorking.appointmentTaskManagementOnPhone}
      </div>
    </div>
  );
}
