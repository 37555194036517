import React, { useState, useEffect } from 'react';
import ProductHeader from './swiper_components/ProductHeader';
import ProductGrade from './swiper_components/ProductGrade';
import Basics from './swiper_components/product_container_components/Basics';
import Server from './swiper_components/product_container_components/Server';
import Communication from './swiper_components/product_container_components/Communication';
import Automation from './swiper_components/product_container_components/Automation';
import MobileWorking from './swiper_components/product_container_components/MobilWorking';
import UserInterface from './swiper_components/product_container_components/UserInterface';
import Support from './swiper_components/product_container_components/Support';
import Focus from './swiper_components/product_container_components/Focus';
import Updates from './swiper_components/product_container_components/Updates';
import Costs from './swiper_components/product_container_components/Costs';
import Rating from './swiper_components/product_container_components/Rating';
import SpecializedDMS from './swiper_components/product_container_components/SpecializedDMS';


export default function SwiperSlideSingle(props) {

    const { name, slidesPerView, selected, subheading } = props;

    const [divHeights, setDivHeights] = useState([]);
    const [divHeightHeader, setDivHeightHeader] = useState();
    

    const calculateHeights = () => {
        let heights = [];
        for (let i = 1; i <= 47; i++) {
            let div = document.querySelector(`.criteria-${i}`);
            if (div) {
                let height = div.offsetHeight;
    
                if (window.innerWidth < 420) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.05;
                    } else {
                        // User is on a desktop device
                        height = height - 0.79;
                    }
                } else if (window.innerWidth < 430) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.05;
                    } else {
                        // User is on a desktop device
                        height = height - 0.79;
                    }
                } else if (window.innerWidth < 450) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.05;
                    } else {
                        // User is on a desktop device
                        height = height - 0.79;
                    }
                } else if (window.innerWidth < 500) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.05;
                    } else {
                        // User is on a desktop device
                        height = height - 0.79;
                    }
                } else if (window.innerWidth < 635) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.05;
                    } else {
                        // User is on a desktop device
                        height = height - 0.79;
                    }
                } else if (window.innerWidth < 735) {
                    if (/Mobi|Android/i.test(navigator.userAgent)) {
                        // User is on a mobile device
                        height = height - 1.06;
                    } else {
                        // User is on a desktop device
                        height = height - 0.8;
                    }
                } else {
                    height = height - 0.1;
                }
    
                heights.push(height);

            } else {
                heights.push(0);
            }
        }
        setDivHeights(heights);
    };

    const calculateHeightHeader = () => {

            let divHeight = document.querySelector(`.product-grade-container.criteria`).offsetHeight;
            setDivHeightHeader(divHeight);
        
    };

    useEffect(() => {
        // Calculate the heights when initializing the component
        calculateHeights();
        calculateHeightHeader();
    
        // Window resize Event Listener
        const handleResize = () => {
            calculateHeights();
            calculateHeightHeader();
        };
    
        // Listen for resize events
        window.addEventListener('resize', handleResize);
    
        // Call handleResize every 5 seconds
      
    

        // Clean up the Event Listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
    
        };
    
    }, [slidesPerView, selected]);
    

    return (
        <div className="product-card">
            <ProductHeader name={name} observe />
            <ProductGrade name={name} hehgt={divHeightHeader} />
            {selected.includes('Basics') && <Basics name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Server/Zugriff') && <Server name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Kommunikation') && <Communication name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Automation') && <Automation name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Mobiles Arbeiten') && <MobileWorking name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Benutzeroberfläche') && <UserInterface name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Spezialisiertes DMS') && <SpecializedDMS name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Support') && <Support name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Schwerpunkt') && <Focus name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Updates') && <Updates name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Kosten') && <Costs name={name} height={divHeights} subheading={subheading}/>}
            {selected.includes('Bewertung') && <Rating name={name} height={divHeights} subheading={subheading}/>}
        </div>
    )
}
