import React from 'react'
import software from '../../software.json'

export default function Automation( props) {

  const { name } = props

  return (
    <div className="automation basics-criteria">
            <div className="criteria-one criteria-16 criteria-design origin">
                {software[name].automation.clientPortal}
            </div>
            <div className="criteria-two criteria-17 criteria-design origin">
                {software[name].automation.clientOnboardingTool}
            </div>
            <div className="criteria-three criteria-18 criteria-design origin">
                {software[name].automation.autoAssignmentBeaEmailAttachments}
            </div>
        </div>
  )
}
