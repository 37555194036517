import React from 'react'
import software from '../../software.json';

export default function Automation( props) {

  const { name, height, subheading } = props;
  

  const mainDivClass = subheading
    ? 'automation basics-criteria values mobil-heading'
    : 'automation basics-criteria values';

  return (
    <div className={mainDivClass} >
    <div style={{ height: `${height[16] - 31.1}px` }} className="criteria-one criteria-design">
        {software[name].automation.clientPortal}
    </div>
    <div style={{ height: `${height[16] - 31.1}px` }} className="criteria-two criteria-design">
        {software[name].automation.clientOnboardingTool}
    </div>
    <div style={{ height: `${height[17] - 31.1}px` }} className="criteria-three criteria-design">
        {software[name].automation.autoAssignmentBeaEmailAttachments}
    </div>
</div>
  )
}
