import React, { useEffect, useRef, useState } from 'react';
import software from '../software.json';

export default function ProductHeader(props) {
  const { name, observe } = props;
  const productCardHeaderRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const gotoUrl = (url) => {
    window.parent.location.href = url;
  }

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(true);
      window.removeEventListener('scroll', handleScroll);
    };

    if (observe) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (observe) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [observe]);

  useEffect(() => {
    if (!observe || !hasScrolled) return;

    const productCardHeader = productCardHeaderRef.current;

    if (!productCardHeader) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log("The product card header is not sticky.");
            document.querySelectorAll('.product-image-container').forEach((element) => {
              element.style.display = 'flex';
            });
          } else {
            console.log("The product card header is sticky now.");
            document.querySelectorAll('.product-image-container').forEach((element) => {
              element.style.display = 'none';
            });
          }
        });
      },
      {
        threshold: 0,
        rootMargin: "0px",
      }
    );

    observer.observe(productCardHeader);


    


    return () => {
      observer.disconnect();
    };
  }, [observe, hasScrolled]);

 
  return (
    <div className="product-card-header" onClick={() => { gotoUrl(software[name].link) }} ref={productCardHeaderRef}>
      <div className="product-title-container">
        <span className="product-title">
          {software[name].name}
        </span>
      </div>
        <div className="product-image-container">
            <object className="product-image" data={software[name].image} type="image/svg+xml">
            </object>
        </div>
      <div className="product-price-container">
        {/* <span className="product-price">
          ab {software[name].price} Euro
        </span> */}
      </div>
    </div>
  );
}
