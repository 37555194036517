import React from 'react'
import software from '../software.json'

export default function CriteriaHeader(props) {
    const { name } = props;

    return (
        <div className="product-card-header criteria">
            <div className="product-title-container">
                <span className="product-title">
                    {software[name].name}
                </span>
            </div>
            <div className="product-image-container">
                <object className="product-image" data={software[name].image} type="image/svg+xml">

                </object>
            </div>
            <div className="product-price-container">
                {/* <span className="product-price">
                    ab {software[name].price} Euro
                </span> */}
            </div>
        </div>
    )
}
