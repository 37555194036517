import React from 'react';
import software from '../../software.json';

export default function Rating(props) {
    const { name } = props;

    return (
      <div className="ratings basics-criteria">
        <div className="criteria-one criteria-46 criteria-design origin">
          {software[name].ratings.googleRating}
        </div>
        <div className="criteria-two criteria-47 criteria-design origin">
          {software[name].ratings.kununuRating}
        </div>
      </div>
    );
}
