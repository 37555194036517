import React, { useEffect, useState, useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperSlideSingle from "./swiper_container/SwiperSlideSingle";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import _ from 'lodash';

// import required modules
import { Navigation } from "swiper";
import SwiperSlideCriteria from "./swiper_container/SwiperSlideCriteria";

export default function SwiperSlider(props) {

    const { selected } = props;

    const [slidesPerView, setSlidesPerView] = useState(3);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleClickRight = () => {
       

        if (!isDisabled) {
            const nextButton = document.querySelector('.swiper-button-next');
            if (nextButton) {
                nextButton.click();
                window.parent.postMessage('forward', '*')
            }
            setIsDisabled(true);
            setTimeout(() => {
                setIsDisabled(false);
            }, 500);
        }
    };

    const handleClickLeft = () => {

        if (!isDisabled) {
            const prevButton = document.querySelector('.swiper-button-prev');
            if (prevButton) {
                prevButton.click();
                window.parent.postMessage('back', '*')
            }
            setIsDisabled(true);
            setTimeout(() => {
                setIsDisabled(false);
            }, 500); // 500ms = 0,5 Sekunden
        }

      
    };


    useEffect(() => {
        const leftButton = document.querySelector('.arrow.left');
        const rightButton = document.querySelector('.arrow.right');

        if (leftButton) {
            leftButton.addEventListener('click', handleClickLeft);
        }

        if (rightButton) {
            rightButton.addEventListener('click', handleClickRight);
        }

        // Aufräumen bei unmount
        return () => {
            if (leftButton) {
                leftButton.removeEventListener('click', handleClickLeft);
            }

            if (rightButton) {
                rightButton.removeEventListener('click', handleClickRight);
            }
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 310) {
                setSlidesPerView(1);
            } else if (window.innerWidth < 1171) {
                setSlidesPerView(1);
            } else {
                setSlidesPerView(3);
            }
        };

        handleResize(); // Call the function once when the component mounts
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        // Handler for 'message' event
        const handleMessage = _.debounce((event) => {
            console.log("message event received:", event.data);
            if (event.data === 'back') {
                handleClickLeft();
            } else if (event.data === 'forward') {
                handleClickRight();
            }
        }, 300); // Debounce for 300 milliseconds
    
        // Add event listener
        window.addEventListener('message', handleMessage);
    
        // Clean up on unmount
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <div className="table-container">
            <div className="criteria-container">

                <SwiperSlide>
                    <SwiperSlideCriteria name="Criteria" selected={selected} />
                </SwiperSlide>

            </div>

            <div className="swiper-container">
                <div className="btn-container-new" >
                    <a href="#" className="arrow left"></a>
                    <a href="#" className="arrow right"></a>
                </div>
                <Swiper
                    slidesPerView={slidesPerView}
                    allowTouchMove={false}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={
                        true
                    }
                    modules={[Navigation]}
                    className="mySwiper"

                >

                    <SwiperSlide>
                        <SwiperSlideSingle name="WinMACS" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperSlideSingle name="Advoware" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide>
    
                    <SwiperSlide>
                        <SwiperSlideSingle name="Advolux" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperSlideSingle name="LEXolution" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide>

                   
                    <SwiperSlide>
                        <SwiperSlideSingle name="Annotext" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide>
                    {/*}
                    <SwiperSlide>
                        <SwiperSlideSingle name="Renostar" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <SwiperSlideSingle name="Nora" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperSlideSingle name="RA-Micro" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                   
                    <SwiperSlide>
                        <SwiperSlideSingle name="Actaport" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperSlideSingle name="DatevAnwalt" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                  
               
                    {/* <SwiperSlide>
                        <SwiperSlideSingle name="BS-Anwalt" slidesPerView={slidesPerView} selected={selected} subheading={false} />
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <SwiperSlideSingle name="A-jur" slidesPerView={slidesPerView} selected={selected} subheading={true} />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}
