import React from 'react';
import software from '../../software.json';

export default function Costs(props) {
    const { name } = props;

    return (
      <div className="cost-variants basics-criteria">
        <div className="criteria-one criteria-40 criteria-design origin">
          {software[name].costVariants.priceIndicator}
        </div>
        <div className="criteria-two criteria-41 criteria-design origin">
          {software[name].costVariants.purchaseSoftware}
        </div>
        <div className="criteria-three criteria-42 criteria-design origin">
          {software[name].costVariants.rentSoftwareMonthly}
        </div>
        <div className="criteria-four criteria-43 criteria-design origin">
          {software[name].costVariants.rentSoftwareAnnually}
        </div>
        <div className="criteria-five criteria-44 criteria-design origin">
          {software[name].costVariants.concurrentUser}
        </div>
        <div className="criteria-six criteria-45 criteria-design origin">
          {software[name].costVariants.namedUser}
        </div>
      </div>
    );
}
