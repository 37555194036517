import React from 'react'
import software from '../software.json'

export default function ProductGrade(props) {

    const { name, hehgt } = props;

    return (
        <div style={{ height: `${hehgt - 31.1}px` }} className="product-grade-container">
            <div className="product-grade">
                <span className="product-grade-heading">
                    <b>{software[name].grade}</b>
                    <span className="product-grade-subheading">
                        <span className="arrow-link">
                            ⟶
                        </span>
                        <span className="software-link">
                            <a target="_blank" rel="noopener noreferrer" href={software[name].link}>Mehr erfahren</a>
                        </span>
                    </span>
                </span>
            </div>
        </div>
    )
}
