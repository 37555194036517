import React from 'react';
import software from '../../software.json';

export default function Costs(props) {

    const { name, height, subheading } = props;

    const mainDivClass = subheading
      ? 'cost-variants basics-criteria values mobil-heading'
      : 'cost-variants basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[39] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].costVariants.priceIndicator}
            </div>
            <div style={{ height: `${height[40] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].costVariants.purchaseSoftware}
            </div>
            <div style={{ height: `${height[41] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].costVariants.rentSoftwareMonthly}
            </div>
            <div style={{ height: `${height[42] - 31.1}px` }} className="criteria-four criteria-design">
                {software[name].costVariants.rentSoftwareAnnually}
            </div>
            <div style={{ height: `${height[43] - 31.1}px` }} className="criteria-five criteria-design">
                {software[name].costVariants.concurrentUser}
            </div>
            <div style={{ height: `${height[44] - 31.1}px` }} className="criteria-six criteria-design">
                {software[name].costVariants.namedUser}
            </div>
        </div>
    );
}
