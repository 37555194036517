import React from 'react'
import software from '../../software.json'

export default function Basics(props) {

    const { name } = props


    return (
        <div className="basics-criteria">
            <div className="criteria-one criteria-1 criteria-design origin">
                {software[name].basics.fileClientStakeholderManagement}
            </div>
            <div className="criteria-two criteria-2 criteria-design origin">
                {software[name].basics.courtDatabase}
            </div>
            <div className="criteria-three criteria-3 criteria-design origin">
                {software[name].basics.appointmentCalendar}
            </div>
            <div className="criteria-four criteria-4 criteria-design origin">
                {software[name].basics.taskManagement}
            </div>
            <div className="criteria-five criteria-5 criteria-design origin">
                {software[name].basics.RVGBilling}
            </div>
            <div className="criteria-six criteria-6 criteria-design origin">
                {software[name].basics.timeBasedBilling}
            </div>
            <div className="criteria-seven criteria-7 criteria-design origin">
                {software[name].basics.textCreationFromFiles}
            </div>
        </div>
    )
}
