import React from 'react';
import software from '../../software.json';

export default function Basics(props) {

    const { name, height, subheading } = props;

    const mainDivClass = subheading
      ? 'basics basics-criteria values mobil-heading'
      : 'basics basics-criteria values';

    return (
        <div className={mainDivClass}>
            <div style={{ height: `${height[0] - 31.1}px` }} className="criteria-one criteria-design">
                {software[name].basics.fileClientStakeholderManagement}
            </div>
            <div style={{ height: `${height[1] - 31.1}px` }} className="criteria-two criteria-design">
                {software[name].basics.courtDatabase}
            </div>
            <div style={{ height: `${height[2] - 31.1}px` }} className="criteria-three criteria-design">
                {software[name].basics.appointmentCalendar}
            </div>
            <div style={{ height: `${height[3] - 31.1}px` }} className="criteria-four criteria-design">
                {software[name].basics.taskManagement}
            </div>
            <div style={{ height: `${height[4] - 31.1}px` }} className="criteria-five criteria-design">
                {software[name].basics.RVGBilling}
            </div>
            <div style={{ height: `${height[5] - 31.1}px` }} className="criteria-six criteria-design">
                {software[name].basics.timeBasedBilling}
            </div>
            <div style={{ height: `${height[6] - 31.1}px` }} className="criteria-seven criteria-design">
                {software[name].basics.textCreationFromFiles}
            </div>
        </div>
    );
}

