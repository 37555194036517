import React from 'react';
import software from '../../software.json';

export default function Rating(props) {
    
  const { name, height, subheading } = props;
  const mainDivClass = subheading
  ? 'ratings basics-criteria values mobil-heading'
  : 'ratings basics-criteria values';

    return (
      <div className={mainDivClass}>
        <div style={{ height: `${height[45] - 31.1}px` }} className="criteria-one criteria-design">
          {software[name].ratings.googleRating}
        </div>
        <div style={{ height: `${height[46] - 31.1}px` }} className="criteria-two criteria-design">
          {software[name].ratings.kununuRating}
        </div>
      </div>
    );
}
